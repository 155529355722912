.About-intro > p {
    font-size: 1.33em;
}

.About-segment {
    padding: 5em 0em;
}

.About img {
    width: 100%;
}
