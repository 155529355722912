.Header {
    position: relative;
}

.content-center .title {
    font-size: 4rem;
}

.content-center .text-center {
    font-size: 1.75rem;
}

.parallax {
    /* The image used */
    /* Set a specific height */
    height: 100vh;
    width: 100%;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.CenterContainer {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}

.center {
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
}

.test:before {
    background: -moz-linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 75%, rgba(0, 0, 0, 0.3) 100%);
    background: -webkit-linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 75%, rgba(0, 0, 0, 0.3) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 75%, rgba(0, 0, 0, 0.3) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000", endColorstr="#4d000000", GradientType=0);
    bottom: 0;
    content: '';
    display: block;
    height: 40%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 2;
}

.page-header {
    min-height: 100vh;
    max-height: 999px;
    padding: 0;
    color: #ffffff;
    position: relative;
    overflow: hidden;
}
.page-header > .content {
    margin-top: 12%;
    text-align: center;
    margin-bottom: 50px;
}
.page-header.page-header-small {
    min-height: 60vh;
    max-height: 440px;
}
.page-header:before {
    background-color: rgba(0, 0, 0, 0.3);
}
.page-header > .container {
    z-index: 2;
    padding-top: 12vh;
    padding-bottom: 40px;
}
.page-header .page-header-image {
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.page-header .content-center {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    color: #ffffff;
    padding: 0 15px;
    width: 100%;
    max-width: 880px;
}
.page-header footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}
.page-header .container {
    height: 100%;
    z-index: 1;
    text-align: center;
    position: relative;
}
.page-header .category,
.page-header .description {
    color: rgba(255, 255, 255, 0.8);
}
.page-header:after,
.page-header:before {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: '';
}
