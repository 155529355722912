@import '~react-image-gallery/styles/css/image-gallery.css';

.Home-segment {
    padding: 1em 0em;
    min-height: 500px;
}

h1.ui.header.Home-h1 {
    font-size: 4em;
    font-weight: normal;
    margin-bottom: 0;
    margin-top: 2em;
}

h2.ui.header.Home-h2 {
    font-size: 1.7em;
    font-weight: normal;
    margin-top: 1.5em;
}

h3.ui.header.Home-h3 {
    font-size: 2em;
}

.Home-segment p {
    font-size: 1.33em;
}

.Home img {
    width: 100%;
}
