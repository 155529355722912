.navbar-santa {
    background-color: #ff0a0e;
}

.navbar {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    min-height: 53px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}
.navbar a {
    vertical-align: middle;
}
.navbar a:not(.btn):not(.dropdown-item) {
    color: #ffffff;
}
.navbar p {
    display: inline-block;
    margin: 0;
    line-height: 21px;
    font-weight: inherit;
    font-size: inherit;
}
.navbar .navbar-nav.navbar-logo {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 49px;
    top: -4px;
}
.navbar .navbar-nav .nav-link.btn {
    padding: 11px 22px;
}
.navbar .navbar-nav .nav-link.btn.btn-lg {
    padding: 15px 48px;
}
.navbar .navbar-nav .nav-link.btn.btn-sm {
    padding: 5px 15px;
}
.navbar .navbar-nav .nav-link:not(.btn) {
    text-transform: uppercase;
    font-size: 0.9142em;
    padding: 0.5rem 0.7rem;
    line-height: 1.625rem;
    font-weight: 500;
}
.navbar .navbar-nav .nav-link:not(.btn) i.fab + p,
.navbar .navbar-nav .nav-link:not(.btn) i.now-ui-icons + p {
    margin-left: 3px;
}
.navbar .navbar-nav .nav-link:not(.btn) i.fab,
.navbar .navbar-nav .nav-link:not(.btn) i.now-ui-icons {
    font-size: 18px;
    position: relative;
    top: 3px;
    text-align: center;
    width: 21px;
}
.navbar .navbar-nav .nav-link:not(.btn) i.now-ui-icons {
    top: 4px;
    font-size: 16px;
}
.navbar .navbar-nav .nav-link:not(.btn).profile-photo .profile-photo-small {
    width: 27px;
    height: 27px;
}
.navbar .navbar-nav .nav-link:not(.btn).disabled {
    opacity: 0.5;
    color: #ffffff;
}
.navbar .navbar-nav .nav-item.active .nav-link:not(.btn),
.navbar .navbar-nav .nav-item .nav-link:not(.btn):focus,
.navbar .navbar-nav .nav-item .nav-link:not(.btn):hover,
.navbar .navbar-nav .nav-item .nav-link:not(.btn):active {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 0.1875rem;
}
.navbar .logo-container {
    width: 27px;
    height: 27px;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 50%;
    border: 1px solid transparent;
}
.navbar .navbar-brand {
    text-transform: uppercase;
    font-size: 0.8571em;
    /* padding-top: 0.5rem;
    padding-bottom: 0.5rem; */
    line-height: 1.625rem;
}
/* .navbar .navbar-toggler {
    width: 37px;
    height: 27px;
    outline: 0;
    cursor: pointer;
}
.navbar .navbar-toggler.navbar-toggler-left {
    position: relative;
    left: 0;
    padding-left: 0;
}
.navbar .navbar-toggler .navbar-toggler-bar.middle-bar {
    width: 17px;
    transition: width 0.2s linear;
}
.navbar .navbar-toggler:hover .navbar-toggler-bar.middle-bar {
    width: 22px;
}
.navbar .button-dropdown .navbar-toggler-bar:nth-child(2) {
    width: 17px;
} */
.navbar.navbar-transparent {
    background-color: transparent !important;
    box-shadow: none;
    color: #ffffff;
    padding-top: 20px !important;
}
.navbar.bg-white:not(.navbar-transparent) a:not(.dropdown-item):not(.btn) {
    color: #888888;
}
.navbar.bg-white:not(.navbar-transparent) a:not(.dropdown-item):not(.btn).disabled {
    opacity: 0.5;
    color: #888888;
}
.navbar.bg-white:not(.navbar-transparent) .button-bar {
    background: #888888;
}
.navbar.bg-white:not(.navbar-transparent) .nav-item.active .nav-link:not(.btn),
.navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):focus,
.navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):hover,
.navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):active {
    background-color: rgba(222, 222, 222, 0.3);
}
.navbar.bg-white:not(.navbar-transparent) .logo-container {
    border: 1px solid #888888;
}
