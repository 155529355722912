.remaining-center {
    text-align: center;
    width: 100%;
    position: relative;
    border-spacing: 5px;
    margin: 0.3em 0 0.2em;
    font-size: 300%;
    display: table;
    table-layout: fixed;
}

.remaining-center .col {
    align-items: center;
}

.remaining-center span {
    display: table-cell;
    font-weight: bolder;
    text-align: center;
    color: #fff;
    line-height: 80%;
    padding: 0.4em 0.1em 0.1em;
    margin: 0 0.05em 0.2em;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.85);
}

.remaining-countdown {
    justify-content: center;
    display: flex;
}
