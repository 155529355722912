.Contact-segment {
    padding: 5em 0em;
}

.Contact-contactinfo > p {
    font-size: 1.33em;
}

.Contact-contactinfo > blockquote {
    font-size: 1.33em;
    font-style: italic;
    border-left: #4a4a4a .4em solid;
}

.Contact-contactinfo > blockquote > p {
    margin: .8em .8em;
}